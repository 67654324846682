export enum Metric {
  UPGRADE_TO_PRO_CLICK = 'Upgrade to Pro Click', // Click on the Upgrade to Pro banner's "Upgrade Now" button
  UPGRADE_TO_ENTERPRISE_CLICK = 'Upgrade to Enterprise Click', // Click on the CTA to Upgrade to Enterprise
  PRO_PLAN_TOGGLE = 'Pro Plan Toggle', // Click on the Pro Plan Toggle
  PRO_PLAN_CONTINUE_BASIC = 'Pro Plan Continue Basic', // Click on "Continue with Basic plan" link
  PRO_PLAN_FREE_TRIAL_CLICK = 'Pro Plan Free Trial Click', // Click on the Pro Plan "Try Free" button
  PRO_PLAN_PURCHASE_CLICK = 'Pro Plan Purchase Click', // Click on the Pro Plan "Purchase" button
  PRO_PLAN_GO_TO_DASHBOARD = 'Pro Plan Go to Dashboard', // Click on the Pro Purchase Success "Go to Dashboard" button
  NAV_CLICK = 'Nav Click', // Click on the Main Nav
  ACCOUNT_MENU_TAB_CLICK = 'Account Menu Tab Click', // Click on the Account Menu Item
  ACCOUNT_MENU_OPEN = 'Account Menu Open', // Open the Account Menu
  LOGO_CLICK = 'Logo Click', // Click on the Logo
  LOGOUT = 'Logout', // Logout
  PAGE_TAB_CLICK = 'Page Tab Click', // Click on the page subtab, e.g. "Earnings", "Analytics"...
  ANALYTICS_TAB_CLICK = 'Analytics Tab Click', // Click on the Analytics subtab, e.g. "session", "scores"...
  WELCOME_CONTINUE_CLICK = 'Welcome Continue Click', // Click on the Welcome "Continue" button
  SIGN_IN_ORG_ID_SUBMIT = 'Sign In Org ID Submit', // Click on the Sign In Org ID "Submit" button
  SIGN_IN = 'Sign In', // Click on the Sign In option
  SIGN_UP = 'Sign Up', // Click on the Sign Up option
  SECURITY_CHECK = 'Security Check', // Click on the Security "Check" button
  SECURITY_REPORT = 'Security Report', // Click on the Security "Report" button
  SECURITY_REPORT_SUBMIT = 'Security Report Submit', // Click on the Security Report Modal "Submit" button
  NEW_SITE_CLICK = 'Button: New Site', // Click on the "New Site" button
  NEW_SITE_MODAL_SELECTION = 'Modal Selection: New Site', // Click on the "New Site" modal selection
  NEW_EXPIRING_SECRET_CLICK = 'Button: New Expiring Secret', // Click on the "New Expiring Secret" button
  NEW_ENCRYPTION_KEY_CLICK = 'Button: New Encryption Key', // Click on the "Create" button, on the Encryption Key tab of the Settings page
  SITE_SETTINGS_CLICK = 'Button: View Site', // Click on the Site "Settings" (to view/edit)
  USER_ACCESS_INTERACT = 'User Access Interact',
  SITE_CANCEL_CLICK = 'Button: Site Cancel', // Click on the Site "Cancel" button
  SITE_SAVE_CLICK = 'Button: Site Save', // Click on the Site "Save" button
  DRILL_DOWN = 'Drill Down', // Click on the Analytics Drill-Down "Run Query" button
  DOWNLOAD = 'Download', // Click to Download
  ONBOARDING_BANNER_HIDE = 'Onboarding Banner Hide', // Click on the onboarding "Got It" button
  CASH_OUT = 'Cash Out', // Click on the "Cash Out"/"Payout scheduled" button
  TRAFFIC_GRAPH_INTERACT = 'Graph: Traffic Data', // Interact with the Traffic Data Graph
  EARNINGS_GRAPH_INTERACT = 'Graph: Earnings', // Interact with the Earnings Graph
  TOOLTIP_MORE_INFO_CLICK = 'Tooltip: More Info', // Click on the "More Info" Tooltip
  ALERTS_TRY_IT_OUT_CLICK = 'Alerts: Try it out Click',
  ALERTS_NOTIFICATION_TAB_CLICK = 'Alerts: Notification Tab Click',
  ALERTS_CONFIGURE_TAB_CLICK = 'Alerts: Configure Tab Click',
  ALERTS_CONFIGURE_THREE_DOTS_CLICK = 'Alerts: Configure Three Dots Click',
  ALERTS_NOTIFICATIONS_THREE_DOTS_CLICK = 'Alerts: Notifications Three Dots Click',
  ALERTS_NOTIFICATIONS_VIEW_ON_DRILL_DOWN = 'Alerts: Notifications View on Drill-Down',
  ALERTS_ADD_ALERT_CLICK = 'Alerts: Add Alert Click',
  ALERTS_EDIT_ALERT_CLICK = 'Alerts: Edit Alert Click',
  ALERTS_DELETE_ALERT_CLICK = 'Alerts: Delete Alert Click',
  ALERTS_DISMISS_ALL_NOTIFICATIONS = 'Alerts: Dismiss All Notifications',
  ALERTS_DISMISS_ONE_NOTIFICATION = 'Alerts: Dismiss One Notification',
  ALERTS_TOGGLE_ONE_ALERT = 'Alerts: Toggle One Alert',
  ALERTS_TOGGLE_ALL_ALERTS = 'Alerts: Toggle All Alerts',
  ALERTS_BANNER_DISMISS_CLICK = 'Alerts: Banner Dismiss Click',
  ALERTS_DOCS_CLICK = 'Alerts: Docs Link',
  THREAT_X_RAY_TRY_IT_OUT_CLICK = 'Threat X-Ray: Try it out Click',
  THREAT_X_RAY_BANNER_DISMISS_CLICK = 'Threat X-Ray: Banner Dismiss Click',
  THREAT_X_RAY_INFO_POPUP_HIDE = 'Threat X-Ray: Info Popup Hide',
  THREAT_X_RAY_BUTTON_CLICK = 'Threat X-Ray: Button Click',
  THREAT_X_RAY_BUTTON_HOVER = 'Threat X-Ray: Button Hover',
  THREAT_X_RAY_DOCS_CLICK = 'Threat X-Ray: Docs Click',
  DRILL_DOWN_TABLE_3_DOTS_CLICK = 'Drill-Down: Table 3 dots click',
  DRILL_DOWN_TABLE_CREATE_RULE = 'Drill-Down: Table Create Rule',
  DRILL_DOWN_TABLE_VIEW_MORE_DETAILS = 'Drill-Down: Table View More Details',
  DRILL_DOWN_RULE_WARNING_DOCS_CLICK = 'Drill-Down: Rule Warning Docs Link',
  DRILL_DOWN_REDIRECT_QUERY = 'Drill-Down: Query view switching accounts',
  DRILL_DOWN_LOAD_QUERY = 'Drill-Down: Load query',
  FRAUD_INTERACTIONS_CLICK = 'Button: View Fraud', // Click on the Fraud "Interactions" table (to view/edit)
  DRILL_DOWN_INFO_POPUP_HIDE = 'Drill-Down Icon: Info Popup Hide',
  PASSKEY_BANNER_DISMISS_CLICK = 'Passkey: Banner Dismiss Click',
  PASSKEY_TRY_IT_OUT_CLICK = 'Passkey: Banner Try it out',
  PASSKEY_ADD_CLICK = 'Passkey: Add Passkey Click',
  PASSKEY_EDIT_CLICK = 'Passkey: Edit Passkey Click',
  PASSKEY_DELETE_CLICK = 'Passkey: Delete Passkey Click',
  PASSWORD_SET_START = 'Password Set Start',
  PASSWORD_RESET_CLICK = 'Password Reset Click',
  VERIFY_LOGIN = 'Verify login',
  TWO_FA_ENABLED_STATE_TOGGLE = '2FA: 2FA Enabled/Disabled Toggle',
  TWO_FA_PASSKEY_ADD_CLICK = '2FA: Add Passkey Click',
  TWO_FA_PASSKEY_EDIT_CLICK = '2FA: Edit Passkey Click',
  TWO_FA_PASSKEY_DELETE_CLICK = '2FA: Delete Passkey Click',
  OPEN_DELETE_ACCOUNT_MODAL = 'Open Delete account modal',
  DELETE_ACCOUNT = 'Delete account',
  DRILL_DOWN_SUGGESTION_CLICK = 'Drill-Down: Suggestion Click',
  SCORE_CONSUMPTION_BANNER_DOCS_CLICK = 'Banner: Score Consumption: Learn More Click',
  SCORE_CONSUMPTION_BANNER_MORE_INFO_CLICK = 'Banner: Score Consumption: View Sites Click',
  FRAUD_REVIEW_APPROVED = 'Fraud: Transaction Status Approved clicked',
  FRAUD_REVIEW_SUSPICIOUS = 'Fraud: Transaction Status Suspicious clicked',
  FRAUD_REVIEW_REJECTED = 'Fraud: Transaction Status Rejected clicked',
  FRAUD_SUSPICIOUS_INTERACTION_ROW_CLICK = 'Fraud: Suspicious Transaction Detail Viewed',
  FRAUD_ALL_INTERACTIONS_ROW_CLICK = 'Fraud: Transaction Detail Viewed',
}

export enum MetricProp {
  PLAN_PRO = 'pro',
  PAGE_BILLING = 'billing',
  PAGE_ENTERPRISE_BILLING = 'billing',
  PAGE_DOCS = 'docs',
  PAGE_CHANGE_PASSWORD = 'change-password',
  PAGE_CHANGE_PAYOUT_EMAIL = 'change-payout-email',
  PAGE_SETTINGS = 'settings',
  PAGE_MANAGE = 'manage',
  PAGE_GENERAL = 'general',
  PAGE_THREAT_MODEL = 'threat-model',
  PAGE_INSTANT_VERIFICATION = 'instant-verification',
  PAGE_ACCESS = 'access',
  PAGE_PRIVATE_LEARNING = 'private-learning',
  PAGE_SESSION = 'session',
  PAGE_CHALLENGE = 'challenge',
  PAGE_SCORES = 'scores',
  PAGE_DRILL_DOWN = 'drill-down',
  PAGE_SITE_NEW = 'site-create',
  PAGE_SITE = 'site',
  PAGE_ANALYTICS = 'analytics',
  PAGE_ALERTS_PROMO = 'alerts-promo',
  PAGE_ALERTS = 'alerts',
  PAGE_OVERVIEW = 'overview',
  PAGE_RULES = 'rules',
  ACTION_CLICK = 'click',
  ACTION_ENTER = 'enter',
  ACTION_ARROW_DOWN = 'arrow-down',
  METHOD_EMAIL = 'email',
  METHOD_REGULAR_PASSKEY = 'regular-passkey',
  METHOD_CONDITIONAL_UI_PASSKEY = 'conditional-ui-passkey',
  CLIENT_USER = 'user',
  CLIENT_ORGANIZATION = 'organization',
  INTERACT_ADD = 'add',
  INTERACT_REMOVE = 'remove',
  INTERACT_SITEKEYS = 'sitekeys',
  INTERACT_WEEK = '7days',
  INTERACT_14D = '14days',
  INTERACT_30D = '30days',
  INTERACT_YEAR = '1year',
  INTERACT_ALL = 'all',
  INTERACT_SINGLE = 'single',
  ACTION_HOVER = 'hover',
  ACTION_FOCUS = 'focus',
  LOCATION_SESSION = 'session',
  LOCATION_SCORES = 'scores',
  LOCATION_PRO_PLAN_SELECT = 'pro-plan-select',
  TARGET_VERIFICATION_RESULTS = 'verification-results',
  TARGET_TOP_ASNS = 'top-asns',
  TARGET_PRO_PLAN_YEARLY_PRICE = 'pro-plan-yearly-price',
  TARGET_ALERTS_DASHBOARD_METHOD = 'alerts-dashboard-notificatio-method',
  TARGET_ALERTS_THRESHOLD = 'alerts-threshold',
  CELL = 'cell',
  ROW = 'row',
  GROUP_BY_THIS = 'group-by-this',
  ADD_TO_WHERE = 'add-to-where',
  SHOW_ME_MORE = 'show-me-more-about-this',
  ADD_GROUP_TO_WHERE = 'add-group-to-where',
  EDIT_ALERT_VIEW = 'edit-alert-view',
  ADD_ALERT_VIEW = 'add-alert-view',
  ALERT_NOTIFICATIONS_TAB = 'alert-notifications-tab',
  ALERT_CONFIGURE_TAB = 'alert-configure-tab',
  ENABLE_ALERTS = 'enable-alerts-widget',
  VERIFY_LOGIN_PASSKEY = 'passkey-as-2fa',
}
