import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  Size,
  Color,
  Panel,
  Text,
  Loader,
  Button,
  RoundedSelect,
  useToast,
  Icon,
} from '@packages/ui';
import { Metric, useAnalytics } from '@packages/analytics';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import {
  PriceTag,
  PlanDescription,
  PageWrapper,
  StyledContinueLink,
  PageLogo,
  PaymentForm,
  DetailsBar,
} from './components';

import { useRootStore } from 'shared/stores';
import { ProPlanType } from 'shared/constants';
// Models
import Logger from 'shared/models/Logger';
import { getQuery } from 'shared/models/History';
import Storage from 'shared/utils/storageUtils';

enum FreeTrialState {
  UNSUBSCRIBED = 'unsubscribed',
  PROCESSING = 'processing',
  SUBSCRIBED = 'subscribed',
}

const SelectProPlan = observer(() => {
  const location = useLocation();
  const selectPlanUrlCouponCode = getQuery().promo;
  const { isPayNow = false, urlCouponCode = selectPlanUrlCouponCode } =
    location?.state || {};
  const { subscriptionStore } = useRootStore();
  const { t } = useTranslation('portal');
  const { trackMetric } = useAnalytics();
  const [isPaymentView, setIsPaymentView] = useState(!!isPayNow);

  const [isLoading, setIsLoading] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [freeTrialState, setFreeTrialState] = useState(
    FreeTrialState.UNSUBSCRIBED,
  );
  const { billingDetails, subscriptionPlans = [] } = subscriptionStore;
  const isFreeTrialEligible = billingDetails?.isFreeTrialEligible;
  const getPlanById = (planId: string) =>
    subscriptionPlans?.find(plan => plan.id === planId);
  const selectedPlan = getPlanById(subscriptionStore.selectedPlan);

  const detailsBarData = { ...selectedPlan, discountPercentage };

  const toast = useToast();

  const getYearlySavings = () => {
    const yearlyPrice = Number(getPlanById(ProPlanType.YEARLY)?.sub_amount);
    const monthlyPrice = Number(getPlanById(ProPlanType.MONTHLY)?.sub_amount);

    const savings = ((monthlyPrice - yearlyPrice) / monthlyPrice) * 100;

    return Math.round(savings) || 0;
  };

  const onDiscountApply = (discount: number) => {
    setDiscountPercentage(discount);
  };

  const planOptions = useMemo(
    () =>
      subscriptionPlans?.map(({ id }) => ({
        id,
        label:
          id === ProPlanType.YEARLY
            ? t('Yearly (Save {{percentage}}%)', {
                percentage: getYearlySavings(),
              })
            : t('Monthly'),
      })),
    [subscriptionPlans], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const plans = await subscriptionStore.fetchSubscriptionPlans();
        await subscriptionStore.fetchBillingDetails();
        const defaultPlan = plans?.find(plan => isYearlyPlan(plan.id));

        if (!defaultPlan) {
          return;
        }

        subscriptionStore.setSelectedPlan(defaultPlan.id);
      } catch (error) {
        toast.error(
          t(
            'Data fetching issue occurred. Please try again or contact support@hcaptcha.com',
          ),
        );
        Logger.error(`couldn't fetch the subscription plans: ${error}`);
      }

      setIsLoading(false);
    };

    Storage.remove('shouldShowSelectPlanPage');

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timeoutId;

    if (freeTrialState === FreeTrialState.SUBSCRIBED) {
      timeoutId = setTimeout(() => {
        window.location.href = `${window.location.origin}/welcome`;
      }, 3000);
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [freeTrialState]);

  const handleProPlanSubscribtion = async () => {
    trackMetric(Metric.PRO_PLAN_FREE_TRIAL_CLICK, {
      plan: subscriptionStore.selectedPlan,
      isFreeTrial: true,
    });

    if (!subscriptionStore.selectedPlan) {
      toast.error(t('Please select the subscription plan'));
      return;
    }

    setFreeTrialState(FreeTrialState.PROCESSING);

    try {
      await subscriptionStore.proceedSubscription(
        subscriptionStore.selectedPlan,
        true,
      );
      setFreeTrialState(FreeTrialState.SUBSCRIBED);
      toast.success(
        t('You have been successfully subscribed for our Pro Plan'),
      );
    } catch (error) {
      setFreeTrialState(FreeTrialState.UNSUBSCRIBED);
      Logger.error(
        `couldn't proceed with the Pro plan Free Trial subscription: ${error}`,
      );
      toast.error(
        t(
          'Subscription issue occurred. Please try again or contact support@hcaptcha.com',
        ),
      );
    }
  };

  const isYearlyPlan = (planId: string) => {
    return planId === ProPlanType.YEARLY;
  };

  const handlePlanToggle = (planId: string) => {
    subscriptionStore.setSelectedPlan(planId);
    trackMetric(Metric.PRO_PLAN_TOGGLE, {
      plan: planId,
    });
  };

  const handleContinueFreePlan = () => {
    trackMetric(Metric.PRO_PLAN_CONTINUE_BASIC);
  };

  return (
    <PageWrapper>
      <Logo />
      <Container>
        <PlanContainer>
          <Card>
            {isLoading ? (
              <CardLoader />
            ) : (
              <ViewsContainer>
                <Views isPaymentView={isPaymentView}>
                  <DetailsView>
                    <PlanHeader emptyData={!planOptions.length}>
                      <CardTitle variant="h3">{t('Pro Plan')}</CardTitle>
                      {planOptions.length > 0 && (
                        <RoundedSelect
                          items={planOptions}
                          selectedItemId={subscriptionStore.selectedPlan}
                          onChange={handlePlanToggle}
                        />
                      )}
                    </PlanHeader>
                    <PriceInfo data={selectedPlan} />
                    <Divider />
                    <PlanDescriptionSection />
                    <ActionsContainer>
                      {isFreeTrialEligible && (
                        <TryFreeButton
                          variant="default"
                          disabled={
                            freeTrialState !== FreeTrialState.UNSUBSCRIBED
                          }
                          onClick={handleProPlanSubscribtion}
                        >
                          {freeTrialState === FreeTrialState.UNSUBSCRIBED &&
                            t('Try Free')}
                          {freeTrialState === FreeTrialState.PROCESSING && (
                            <ButtonContent>
                              {t('Processing')}
                              <ButtonLoadingIndicator size={14} />
                            </ButtonContent>
                          )}
                          {freeTrialState === FreeTrialState.SUBSCRIBED && (
                            <ButtonContent>
                              {t('Successfully Subscribed')}
                              <ButtonIcon icon="check" iconSize={14} />
                            </ButtonContent>
                          )}
                        </TryFreeButton>
                      )}
                      {freeTrialState !== FreeTrialState.SUBSCRIBED &&
                        planOptions.length > 0 && (
                          <Button
                            variant="outlined"
                            disabled={
                              freeTrialState === FreeTrialState.PROCESSING
                            }
                            onClick={() => setIsPaymentView(true)}
                          >
                            {t('Pay Now')}
                          </Button>
                        )}
                    </ActionsContainer>
                  </DetailsView>
                  <PaymentView>
                    <PlanHeader>
                      <PaymentCardTitle
                        variant="h3"
                        onClick={() => setIsPaymentView(false)}
                      >
                        <BackIcon icon="arrowUpRight" iconSize="16" />
                        {t('Payment Details')}
                      </PaymentCardTitle>
                    </PlanHeader>
                    <PlanDetailsBar data={detailsBarData} />
                    <Divider />
                    <ProPlanPaymentForm
                      isPaymentView={isPaymentView}
                      urlCouponCode={urlCouponCode}
                      onDiscountApply={onDiscountApply}
                    />
                    {isFreeTrialEligible && (
                      <FreeTrialNote>
                        <Text variant="small">
                          {t('* Free Trial included')}
                        </Text>
                      </FreeTrialNote>
                    )}
                  </PaymentView>
                </Views>
              </ViewsContainer>
            )}
          </Card>
          <LinkWrapper>
            <StyledContinueLink onClick={handleContinueFreePlan} to="/welcome">
              <span>{t('Continue with Basic Plan')}</span>
              <ChevronIcon icon="chevronRight" iconSize="14" />
            </StyledContinueLink>
          </LinkWrapper>
        </PlanContainer>
      </Container>
    </PageWrapper>
  );
});

const CardLoader = styled(Loader)`
  margin: 40px;
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonIcon = styled(Icon)`
  margin-left: 5px;
`;

const ButtonLoadingIndicator = styled(Loader)`
  margin-left: 5px;
  min-height: auto;
`;

const Container = styled.div`
  width: 100%;
`;

const ViewsContainer = styled.div`
  overflow: hidden;
`;

const Views = styled.div<{
  isPaymentView?: boolean;
}>`
  overflow: visible;
  display: flex;
  width: 200%;
  transition: left 0.35s ease-in-out;
  position: relative;
  left: ${({ isPaymentView }) => (isPaymentView ? '-100%' : '0')};
`;

const View = styled.div<{
  isHidden?: boolean;
}>`
  transition: left, right 1.15s ease-in-out;
  position: relative;
  display: inline-block;
  width: 50%;
  height: auto;
  box-sizing: border-box;
  padding: 24px;

  @media (min-width: 500px) {
    padding: 40px;
  }
`;

const DetailsView = styled(View)``;

const PaymentView = styled(View)``;

const PlanDetailsBar = styled(DetailsBar)``;

const ProPlanPaymentForm = styled(PaymentForm)`
  margin-top: 20px;

  @media (min-width: 500px) {
    margin-top: 35px;
  }
`;

const FreeTrialNote = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;

const BackIcon = styled(Icon)`
  transform: rotate(225deg);
  margin-right: 8px;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Logo = styled(PageLogo)`
  @media (max-width: ${Size.mobile}) {
    margin-top: 7px;
  }
`;

const PlanContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 auto;
  width: 90%;

  @media (min-width: 500px) {
    width: 440px;
  }
`;

const Card = styled(Panel)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Color.grey200};
`;

const CardTitle = styled(Text)`
  display: inline-flex;
  align-items: center;
`;

const PaymentCardTitle = styled(CardTitle)`
  cursor: pointer;
`;

const PlanHeader = styled.div<{
  emptyData?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  ${({ emptyData }) => emptyData && 'margin-bottom: 20px;'}

  @media (min-width: 400px) {
    flex-direction: row;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
`;

const ChevronIcon = styled(Icon)`
  margin-left: 4px;
`;

const TryFreeButton = styled(Button)`
  margin-bottom: 16px;
`;

const PriceInfo = styled(PriceTag)`
  padding: 27px 0 24px;
  position: relative;

  @media (min-width: 500px) {
    padding: 42px 0 40px;
  }
`;

const PlanDescriptionSection = styled(PlanDescription)`
  margin: 24px 0;
  max-height: 54px;
  overflow: auto;

  @media (min-width: 500px) {
    max-height: unset;
    margin: 40px 0;
  }
`;

export default SelectProPlan;
