// Config
import { APP } from '../config/Config';

const featureToggle: any = {
  showSpendButton: false,
  showWebhooks: false,
  showAudienceFilters: false,
  showCharitySlider: false,
  showRefEarnings: false,
  showPrimitiveJobForm: userData => userData.features.job_creation_enabled,
  showResultsDebugToolBar: APP.env !== 'production',
  showLaunchGroups: userData => userData.features.launch_groups,
  showInstantVerification: userData => userData.features.instant_verification,
  showPrivateLearning: userData => userData.features.private_learning,
  showProductReview: userData => userData.features.product_review,
  showCustomThreat: userData => userData.features.custom_threat_models,
  showCustomTheme: userData => userData.features.custom_theme,
  showKeepRunning: userData => userData.features.continuously_running_jobs,
  showAlerts: userData => userData.features.alerts,
  showQueries: userData => userData.features.queries,
  salesEng: userData => userData.features.sales_eng,
  showApt: userData => userData.features.apt,
  showFraudProtection: userData => userData.features.fraud,
};
const Data = {
  featureToggle,
};
export default Data;
