import axios from 'axios';
import * as Sentry from '@sentry/browser';

// Config
import { APP, USER_LOGGED_OUT } from '../config/Config';
import User from '../models/User';

import rootStore from 'shared/stores/RootStore';

// temporary file which will be removed when we start to use the js-api-client
// https://github.com/IntuitionMachines/dashboard-js-client-api
const getClient = (csrfToken?: string | null, endpoint?: string) => {
  const options = {
    baseURL: endpoint || APP.endpoint,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': User.getCSRFToken(),
    },
  };
  const client = axios.create(options);
  client.interceptors.request.use(
    requestConfig => requestConfig,
    requestError => {
      Sentry.captureException(requestError);
      return Promise.reject(requestError);
    },
  );
  client.interceptors.response.use(
    response => response,
    async error => {
      const { status } = error.response || {};

      if (
        (status === 302 || status === 301) &&
        !!error.response.data.redirect
      ) {
        const { pathname } = new URL(error.response.data.redirect);
        window.location.href = `${window.location.origin}${pathname}`;
      }

      if (error.response && error.response.data) {
        const { csrf_token } = error.response.data;
        !!csrf_token && User.setCSRFToken(csrf_token);
      }

      if (status === 401) {
        const { error: errorMessage } = error.response.data;

        if (errorMessage === USER_LOGGED_OUT) {
          rootStore.logout();
          return Promise.reject(new Error(USER_LOGGED_OUT));
        }
      }

      // If request is canceled, form validation - status 400, redirect - status 429, or user isn't loggedIn - status 401
      // Do not capture request error with sentry
      const captureError =
        !axios.isCancel(error) && !(status === 400 || status === 429);

      if (captureError) {
        Sentry.configureScope(scope => {
          scope.setExtra('response', error.response);
        });
        Sentry.captureException(error);
      }

      return Promise.reject(error);
    },
  );
  return client;
};

export default getClient;
